










































































import { Component, Vue } from "vue-property-decorator";
import { Store } from "vuex";
import { IUserProfileUpdate } from "@/interfaces";
import { appName } from "@/env";
import { commitAddNotification } from "@/store/main/mutations";
import { dispatchResetPassword } from "@/store/main/actions";
import Header from "./Header.vue";
import Footer from "./Footer.vue";

@Component({
  components: { Header, Footer },
})
export default class UserProfileEdit extends Vue {
  public appName = appName;
  public valid = true;
  public password1 = "";
  public password2 = "";
  public passwordError = "";

  public checkPassword() {
    if (this.password1) {
      if (this.password1.length < 8) {
        this.passwordError =
          "Please ensure the password is at least 8 characters";
        return false;
      } else if (!this.password1.match(/[A-Z]/)) {
        this.passwordError =
          "Please ensure the password has at least 1 capital letter";
        return false;
      } else if (!this.password1.match(/\d/)) {
        this.passwordError = "Please ensure the password has at least 1 number";
        return false;
      }
      this.passwordError = "";
      return true;
    } else {
      return false;
    }
  }

  public validate() {
    return this.checkPassword() && this.password1 === this.password2;
  }
  public mounted() {
    //this.checkToken();
  }

  public reset() {
    this.password1 = "";
    this.password2 = "";
    this.$validator.reset();
  }

  public cancel() {
    this.$router.push("/");
  }

  public checkToken() {
    const token = this.$router.currentRoute.query.token as string;
    if (!token) {
      commitAddNotification(this.$store, {
        content: "No token provided in the URL, start a new password recovery",
        color: "error",
      });
      this.$router.push("/recover-password");
    } else {
      return token;
    }
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      await dispatchResetPassword(this.$store, {
        token: "",
        password: this.password1,
      });
      this.$router.push("/");
    }
  }
}
